@import 'errors.scss';
@import 'forms.scss';
@import 'modal.scss';
@import 'template.scss';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~@ctrl/ngx-emoji-mart/picker';
@import '../dashboard/_styles/dashboard.component.scss';

html, body {
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    overflow: auto;
    background-color: $body-background-color;
    color: $body-text-color;
}

.tim-icon {
    cursor:pointer;
}

.emojibutton {
   
        background: transparent;
        border: 0;
        transform: translateX(-40px);
        cursor: pointer;
        &:focus {
           outline: 0;
        }
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #b7b7b7;
}

.main-color-bg {
    border-color: transparent !important;
    background-image: none !important;
    background-color: #56a5a6 !important;
    border-color: #75c3c4;
    color: #ffffff !important;
}

.main-panel {
    padding-top: 30px;
}

.dash-box {
    text-align: center;
}

.progress-bar {
    background: #333333;
    color: #ffffff;
}

.list-group > label:hover {
    background-color: #F4F4F4;
    color: black;
    cursor: pointer;
}

.card {
    padding-bottom:20px;
}

.list-group {
    padding-bottom:20px;
}

.list-group > label {
    font-weight: normal !important;
    margin-bottom: 0px;
}

.white-box {
    background-image: none !important;
    min-height: 160px;
}

.pointer-cursor {
    cursor: pointer;
}

.progress {
    background-image: none !important;
    background-color: #c4c4c4!important;
}
