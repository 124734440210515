﻿/*@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.alert {
    margin: 5px;
    padding: 20px;
    position: absolute;
    left: 0;
    display: block;
    color: white;
    z-index: 999;
    width: 40%;
    height: auto;
    animation: fadein 1s linear forwards;
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.alert-removed {
    animation: fadeout 1s linear forwards;
}

.alert-closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.error-alert {
    background-color: red;
}

.success-alert {
    background-color: green;
}

.alert-closebtn:hover {
    color: black;
}

.error-help-block {
    color: red;
    display: block;
    margin-top: 5px;
    padding-left: 5px;
    text-align: left;
}*/