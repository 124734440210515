﻿@import '../template.scss';

.tim-button {
    @include control-template;

    &:hover {
        @include control-template-hover;
    }

    &.disabled, &[disabled], fieldset[disabled] & {
        @include control-template-disabled;
    }

    &-submit {
        margin-top: 30px;
    }

    &-form {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
