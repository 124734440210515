﻿/*input[type="checkbox"] {
    display: none;
}*/

.form-checkbox input[type="checkbox"] + label {
    margin-top: 7px;
}

input[type="checkbox"] + label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

input[type="checkbox"] + label:last-child {
    margin-bottom: 0;
}

input[type="checkbox"] + label:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #252422;
    position: absolute;
    left: 0;
    top: 0;
    opacity: .7;
    -webkit-transition: all .12s, border-color .08s;
    transition: all .12s, border-color .08s;
}

input[disabled] input[type="checkbox"] + label:before {
    border-color: #ccc;
}

input[type="checkbox"]:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: #77ad3f;
    border-bottom-color: #77ad3f;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
