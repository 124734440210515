﻿$text-color: #fff;
$border-color: #75c3c4;
$background-color: #56a5a6;
$border-bottom-color: #75c3c4;

$text-color-hover: #fff;
$border-color-hover: #75c3c4;
$background-color-hover: #70d7d8;
$border-bottom-color-hover: #75c3c4;

$text-color-disabled: #666666;
$border-color-disabled: #999999;
$background-color-disabled: #cccccc;
$border-bottom-color-disabled: #999999;

$text-color-active: #fff;
$border-color-active: #75c3c4;
$background-color-active: #56a5a6;
$border-bottom-color-active: #75c3c4;

@mixin control-template {
    color: $text-color;
    border: 1px solid $border-color;
    background-color: $background-color;
    border-bottom: 5px solid $border-bottom-color;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 1.5em;
    text-align: center;
    position: relative;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 0;
}

@mixin control-template-hover {
    color: $text-color-hover;
    border-color: $border-color-hover;
    background-color: $background-color-hover;
    border-bottom-color: $border-bottom-color-hover;
}

@mixin control-template-disabled {
    color: $text-color-disabled;
    border-color: $border-color-disabled;
    background-color: $background-color-disabled;
    border-bottom-color: $border-bottom-color-disabled;
    cursor: default;
    pointer-events: none;
    opacity: 0.65;
}