﻿@import 'colors.scss';

.titlecontainer {
    margin-top: 20px;
}

.title {
    margin: 0;
}

.hr-title {
    margin-top: 0;
}

.hr-title-adapted {
    margin-bottom: 0;
}

select::-ms-expand {
    display: none; /* hide the default arrow in ie10 and ie11 */
}

.no-result {
    padding-top: 30px;
    padding-left: 20px;
}

.select-control {
    display: inline-block;
}

.detail-outer {
    padding: 20px;
}

.detail-inner {
    border: 2px solid black;
    min-height: 810px;
}

.content-detail-outer {
    border: 0px;
}

.content-detail-inner-header {
    min-height: 30px;
}

.content-detail-inner {
    border: 2px solid black;
    min-height: 770px;
}

.detail-info {
    padding: 10px;
}

.detail-info h2 {
    margin: 0;
    margin-bottom: 4px;
}

.detail-info-block {
    margin-bottom: 10px;
}

.detail-info-line {
    margin-bottom: 4px;
    font-weight: bold;
}

.detail-title {
    padding-left: 20px;
    padding-right: 20px;
}

.detail-info-line-content {
    padding-left: 15px;
    font-weight: normal;
}

.detail-tab-container {
    height: 30px;
}

.content-detail-outer .nav > li > a {
    position: relative;
    display: block;
    padding: 5px 15px;
    color: black;
    background-color: lightgray;
    border-top: black 1px solid;
    border-left: black 1px solid;
    border-right: black 1px solid;
}

.mydp .selection {
    display: none !important;
}

.btnclear {
    display: none !important;
}

.mydp {
    border: 0px;
    width: 28px !important;
}

.float-right {
    float: right;
}

.cursor-pointer {
    cursor: pointer;
}

.input-size {
    font-size: 30px;
}

.header-fixed {
    width: 100%
}

.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
    display: block;
}

.header-fixed > thead > tr > th {
    white-space: nowrap;
}

.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
}

.header-fixed > tbody {
    overflow-y: auto;
    height: 34vh;
}

.header-fixed-small > tbody {
    overflow-y: auto;
    height: 30vh;
}

.header-fixed-smaller > tbody {
    overflow-y: auto;
    height: 28vh;
}

.header-fixed4 > tbody > tr > td,
.header-fixed4 > thead > tr > th {
    width: 25%;
    float: left;
}

.header-fixed5 > tbody > tr > td,
.header-fixed5 > thead > tr > th {
    width: 20%;
    float: left;
}

.header-fixed6 > tbody > tr > td,
.header-fixed6 > thead > tr > th {
    width: 16.66%;
    float: left;
}

.header-fixed2 > tbody > tr > td,
.header-fixed2 > thead > tr > th {
    width: 50%;
    float: left;
}

.row {
    padding-bottom: 0px;
}

.label-inside {
    padding-left: 10px;
    font-weight: 700;
}

.input-padding {
    padding-bottom: 10px;
}

.form-button-margin {
    margin-right: 10px;
    margin-bottom: 10px;
}

.label-input-aline {
    margin-top: 7px;
}

.answer-container {
    border: solid;
    height: 368px;
    overflow: auto;
}

.dropdown-menu {
    margin: 0px;
}

.background-active:hover {
    background-color: $body-background-color-hover;
    color: $body-text-color-hover;
}

.full-block {
    height: 92vh;
}

.part-block {
    height: 46vh;
}

.active-div {
    background-color: $active-div-background-color;
    color: $active-div-text-color;
}

.list-container:hover {
    cursor: pointer;
    background-color: $list-container-background-color-hover;
    color: $list-container-text-color-hover;
}

.nav-tabs > li > a {
    color: $tabs-text-color;
    background-color: $tabs-background-color;
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
    color: $tabs-text-color-hover;
    background-color: $tabs-background-color-hover;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
    color: $tabs-active-text-color-hover;
    background-color: $tabs-active-background-color-hover;
}

.table > thead:first-child > tr:first-child > th {
    border-bottom: 0;
}

.navbar .nav-item:hover .dropdown-menu{ display: block; }

.navbar .nav-item {
    padding-right: 15px;
    padding-left: 15px;
}

.nav-link {
    margin-top: 0px;
}

.normal-mouse {
    cursor: default;
}

.normal-mouse:hover {
    cursor: default;
}

.card-title {
    font-size: 16px;
    height: 5px;
}

.emoji-toggle-button {
    font-style: normal;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
    position: absolute;
    right: 15px;
    bottom: 0px;
}

.emotji-height {
    height: 32px;
}

textarea {
    resize: none;
}

#emoji-picker textarea {
    display: none;
}

.emoji-position {
    position: absolute;
    top: 7%;
    right: -67%;
}

.no-box-shadow {
    box-shadow:none;
}

.no-box-shadow:focus {
    border: none;
    box-shadow: none;
}

.input-align {
    padding-left: 25px;
}

@media (min-width: 992px){
    .no-hori-padding {
        padding-left: 0px;
        padding-right: 0px;
    }
    .no-right-padding {
        padding-right: 0px
    }

    .custom-width-date-input {
        width: 12.33333333%;
    }

    .custom-width-datepicker {
        width: 3.33333333%;
        text-align: right;
        margin-right: 1%;
    }

}

.no-click {
    pointer-events: none;
}

.radio-overlap {
    padding-left: 22px;
    position: absolute;
    left: 20px;
}

.image-many-size {
    max-height: 100px;
    max-width: 100px;
}

// input colors
.form-control, button.btnpicker.btnpickerenabled {
    background-color: $form-background-color;
    border-color: $form-border-color;
    color: $form-text-color;
}

.form-control:hover, 
button.btnpicker.btnpickerenabled:hover, 
.form-control:active, 
button.btnpicker.btnpickerenabled:active, 
input.form-control:focus, 
button.btnpicker.btnpickerenabled:focus, 
textarea.form-control:focus, 
select.form-control:focus {
    background-color: $form-background-color-hover;
    border-color: $form-border-color-hover;
    color: $form-text-color-hover;
}

// Navbar colors
.navbar-expand-lg, 
.navbar-expand-lg .navbar-collapse, 
.navbar-expand-lg .navbar-form, 
.navbar-expand-lg .navbar-header, 
.navbar-expand-lg .navbar-nav > li > a, 
.navbar-expand-lg .navbar-brand, 
.navbar-expand-lg .navbar-toggle {
    background-color: $navbar-background-color;
    border-color: $navbar-border-color;
    color: $navbar-text-color;
}

.navbar-expand-lg .navbar-brand:hover, 
.navbar-expand-lg .navbar-brand:focus, 
.navbar-expand-lg .navbar-nav > li > a:hover, 
.navbar-expand-lg .navbar-nav > li > a:focus, 
.navbar-expand-lg .navbar-toggle:hover, 
.navbar-expand-lg .navbar-toggle:focus {
    color: $navbar-text-color-hover;
    background-color: $navbar-background-color-hover;
    border-color: $navbar-border-color-hover;
}

.navbar-expand-lg .navbar-toggle .icon-bar {
    background-color: $navbar-text-color;
}
//Colors for the tickets priority and status
.Hoog {
    color: $hoog;
}

.Middel {
    color: $middel;
}

.Laag {
    color: $laag;
}

.Open {
    color: $open;
}

.In.behandeling {
    color: $in-behandeling;
}

.Gesloten {
    color: $gesloten;
}

.Nieuw {
    color: $nieuw;
}

.Termijndag {
    color: $termijndag;
}

.Betaald {
    color: $betaald;
}

.Gewijzigd {
    color: $gewijzigd;
}

.Geweigerd {
    color: $geweigerd;
}

.Besteld {
    color: $besteld;
}

.Aangemaakt {
    color: $aangemaakt;
}

.loadersmall {
    border: 3px solid #8DD5D6;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-top: 3px solid #ffffff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    float: right;
    margin-right: 20px;
    margin-top: 20px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.tabset {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
}